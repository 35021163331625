<div class="p-3">
    <app-wap-user-detail />
    <ol style="list-style: decimal; margin-left: 20px; margin-top: 20px; margin-bottom: 20px;">
        <li style="cursor: pointer;"> <a routerLink="pools">Pools</a></li>
        <li style="cursor: pointer;"> <a routerLink="deposit">Deposit</a></li>
        <li style="cursor: pointer;"> <a routerLink="withdraw">Withdraw</a></li>
        <li style="cursor: pointer;"> <a routerLink="transaction">Transaction</a></li>
        <li style="cursor: pointer;"> <a routerLink="prize">Prize</a></li>
        <li style="cursor: pointer;"> <a routerLink="news">News</a></li>
        <li style="cursor: pointer;"> <a routerLink="history-number">History Number</a></li>
    </ol>

    <a href="">Logout</a>
</div>