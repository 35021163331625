<div class="p-3">
    <app-wap-user-detail />
    <div class="mt-6">

        <div>
            <input type="text" placeholder="Cari..." class="w-full p-2" (input)="onSearching($event)"/>
        </div>

        <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
            <div class="px-2 text-sm text-center cursor-pointer p-1 bg-slate-950">
                TOTO MACAU 4D
            </div>
            <div class="bg-slate-800 px-2 text-sm text-center cursor-pointer p-1">
                TOTO MACAU 4D
            </div>
            <div class="bg-slate-800 px-2 text-sm text-center cursor-pointer p-1">
                TOTO MACAU 4D
            </div>
            <div class="bg-slate-800 px-2 text-sm text-center cursor-pointer p-1">
                TOTO MACAU 4D
            </div>
        </div>

    </div>
</div>