import { Component, HostListener, Input } from '@angular/core';

export type IAlertVariant = 'success' | 'danger' | 'warning' | 'info';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent {
    @Input() variant: IAlertVariant = 'success'
    @Input() message: string = ''
    alerts: { variant: IAlertVariant, message: string, isClosing: boolean }[] = []

    show(alert: { variant: IAlertVariant, message: string, duration?: number }) {
        const newAlert = { ...alert, isClosing: false }
        this.alerts.push(newAlert)

        setTimeout(() => {
            newAlert.isClosing = true
            setTimeout(() => {
                this.alerts = this.alerts.filter(a => a !== newAlert)
            }, 200)
        }, alert.duration || 3000)
    }

    @HostListener('window:alert.show', ['$event'])
    listenAlert(e: any) {
        this.show({
            variant: e.detail.variant,
            message: e.detail.text,
            duration: e.detail.duration,
        })
    }
}