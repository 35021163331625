import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-wap-user-detail',
  templateUrl: './wap-user-detail.component.html',
  styleUrl: './wap-user-detail.component.scss',
  imports: [
    RouterLink
  ]
})
export class WapUserDetailComponent {

}
