<h2 class="text-2xl font-bold mb-4">Nama BrandKU</h2>

<table>
    <tbody>
        <tr>
            <td>User</td>
            <td>:</td>
            <td>Testing</td>
        </tr>
        <tr>
            <td>Saldo</td>
            <td>:</td>
            <td>0</td>
        </tr>
    </tbody>
</table>

<div class="mt-4">
    <a routerLink="/wap" class="bg-slate-700 p-2">Back To Menu</a>
</div>