import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-wap-login',
  templateUrl: './wap-login.component.html',
  styleUrl: './wap-login.component.scss',
  imports: [
    RouterLink

  ]
})
export class WapLoginComponent {

}
