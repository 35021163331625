import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WapUserDetailComponent } from '../../shared/components/wap/wap-user-detail/wap-user-detail.component';

@Component({
  standalone: true,
  selector: 'app-wap-version',
  templateUrl: './wap-version.component.html',
  styleUrl: './wap-version.component.scss',
  imports: [
    RouterLink,
    WapUserDetailComponent
  ]
})
export class WapVersionComponent {

}
