import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WapVersionComponent } from './wap/wap-version/wap-version.component';
import { WapLoginComponent } from './wap/wap-login/wap-login.component';
import { WapListGameComponent } from './wap/wap-list-game/wap-list-game.component';
import { WapDepositComponent } from './wap/wap-deposit/wap-deposit.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./regular-pages/regular-pages-routing.module').then(m => m.RegularPagesRoutingModule),
  },
  {
    path: '',
    loadChildren: () => import('./user-pages/user-pages-routing.module').then(m => m.UserPagesRoutingModule),
  },
  {
    path: '',
    loadChildren: () => import('./admin-pages/admin-pages-routing.module').then(m => m.AdminPagesRoutingModule),
  },
  {
    path: 'wap',
    component: WapVersionComponent,
  },
  {
    path: 'wap/login',
    component: WapLoginComponent,
  },
  {
    path: 'wap/pools',
    component: WapListGameComponent,
  },
  {
    path: 'wap/deposit',
    component: WapDepositComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
