import { Component } from '@angular/core';
import { WapUserDetailComponent } from '../../shared/components/wap/wap-user-detail/wap-user-detail.component';

@Component({
  standalone: true,
  selector: 'app-wap-deposit',
  templateUrl: './wap-deposit.component.html',
  styleUrl: './wap-deposit.component.scss',
  imports: [
    WapUserDetailComponent
  ]
})
export class WapDepositComponent {

}
