import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserPagesComponent } from './user-pages/user-pages.component';
import { AdminPagesComponent } from './admin-pages/admin-pages.component';
import { AdminPagesRoutingModule } from './admin-pages/admin-pages-routing.module';
import { UserPagesRoutingModule } from './user-pages/user-pages-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './shared/components/alert/alert.component';
// import { ButtonComponent } from './shared/components/button/button.component';
// import { LoadingIndicatorComponent } from './shared/components/loading-indicator/loading-indicator.component';
import { DropdownComponent } from './shared/components/dropdown/dropdown.component';
import { RouterLink, RouterModule } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RegularPagesRoutingModule } from './regular-pages/regular-pages-routing.module';
import { NavbarComponent } from "./shared/components/navbar/navbar/navbar.component";
// import { TogelPageComponent } from './user-pages/components/dynamic-pages/togel-page/togel-page.component';
import { AnyPageComponent } from './user-pages/components/dynamic-pages/any-page/any-page.component';
import { CommonModule } from '@angular/common';
// import { WapDepositComponent } from './wap/wap-deposit/wap-deposit.component';
// import { WapUserDetailComponent } from './shared/components/wap/wap-user-detail/wap-user-detail.component';
// import { WapListGameComponent } from './wap/wap-list-game/wap-list-game.component';
// import { WapLoginComponent } from './wap/wap-login/wap-login.component';
// import { WapVersionComponent } fro./user-pages/components/wap-version/wap-version.componentent';
// import { PopupActionComponent } from './popup-action/popup-action.component';
// import { ReferralComponent } from './referral/referral.component';
// import { CashbackComponent } from './user-pages/components/user/bonus/cashback/cashback.component';
// import { CombinePromoComponent } from './user-pages/components/user/bonus/combine-promo/combine-promo.component';
// import { CommissionComponent } from './user-pages/components/user/bonus/commission/commission.component';
// import { BonusComponent } from './bonus/bonus.component';
// import { BankingComponent } from './user-pages/components/user/banking/banking.component';
// import { WithdrawComponent } from './user-pages/components/user/withdraw/withdraw.component';
// import { DepositComponent } from './deposit/deposit.component';
// import { LivechatComponent } from './shared/components/livechat/livechat.component';
// import { ProfileComponent } from './profile/profile.component';
// import { AccordionComponent } from './shared/components/accordion/accordion.component';
// import { TogelNumberComponent } from './shared/components/togel-number/togel-number.component';

@NgModule({
  declarations: [
    AppComponent,
    // UserPagesComponent,
    AlertComponent,
    // ButtonComponent,
    // LoadingIndicatorComponent,
    DropdownComponent,
    AnyPageComponent,
    // WapDepositComponent,
    // WapUserDetailComponent,
    // WapListGameComponent,
    // WapLoginComponent,
    // WapVersionComponent,
    // PopupActionComponent,
    // ReferralComponent,
    // CashbackComponent,
    // CombinePromoComponent,
    // CommissionComponent,
    // BonusComponent,
    // BankingComponent,
    // WithdrawComponent,
    // DepositComponent,
    // LivechatComponent,
    // ProfileComponent,
    // AccordionComponent,
    // TogelNumberComponent,
    // TogelPageComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    BrowserModule,
    AppRoutingModule,
    AdminPagesRoutingModule,
    UserPagesRoutingModule,
    RegularPagesRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    NavbarComponent,
    UserPagesComponent
],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
