<h2 class="text-2xl font-bold mb-4">Nama BrandKU</h2>
<form>
    <table>
        <tbody>
            <tr>
                <td>Login</td>
                <td>:</td>
                <td><input type="text" id="entered_login" name="entered_login"></td>
            </tr>
            <tr>
                <td>Password</td>
                <td>:</td>
                <td><input type="password" id="entered_password" name="entered_password"></td>
            </tr>
            <tr>
                <td><a href="/signup">Register</a></td>
                <td></td>
                <td><button routerLink="/wap" type="button" name="submit" id="loginBtnHeader">Login</button></td>
            </tr>
        </tbody>
    </table>
</form>

<a class="mt-32" rout   erLink="/">Switch Mobile Version</a>