<div class="p-3">
    <app-wap-user-detail />

    <h3 class="text-xl font-bold mt-5">Deposit</h3>

    <table>
        <tr>
            <td>Nama Bank</td>
            <td> : </td>
            <td>bca</td>
        </tr>
        <tr>
            <td>Nama Rekening</td>
            <td> : </td>
            <td>Testing</td>
        </tr>
        <tr>
            <td>No. Rekening</td>
            <td> : </td>
            <td>096-123-****</td>
        </tr>
        <tr>
            <td>Pilihan Bank</td>
            <td> : </td>
            <td>
                <select name="" id="" class="text-slate-900 px-2">
                    <option value="BCA" class="text-slate-900">BCA</option>
                    <option value="BNI" class="text-slate-900">BNI</option>
                    <option value="BRI" class="text-slate-900">BRI</option>
                </select>
            </td>
        </tr>
    </table>

    <hr class="mt-4 mb-4">

    <table>
        <tr>
            <td>Nama Bank</td>
            <td> : </td>
            <td>BCA</td>
        </tr>
        <tr>
            <td>Nama Rekening</td>
            <td> : </td>
            <td>NDAYA PUTRI</td>
        </tr>
        <tr>
            <td>No. Rekening</td>
            <td> : </td>
            <td class="font-bold">09812345678 <button class="!bg-slate-900 p-1">Copy</button></td>
        </tr>
    </table>

    <hr class="mt-4 mb-4">

    <table>
        <tr>
            <td>Jumlah Deposit</td>
            <td> : </td>
            <td><input type="number" placeholder="Masukan deposit" class="text-slate-900 px-2"> IDR</td>
        </tr>
    </table>

    <div>
        <button class="bg-slate-700 p-1 px-2 mt-3">KONFIRMASI</button>
    </div>


    <div class="mt-3">
        <p>Status Bank</p>

        <ol style="list-style: decimal; margin-left: 20px; margin-top: 0px; margin-bottom: 20px;">
            <li> Minimal deposit = 10000,- IDR. </li>
            <li> Untuk transfer antar bank / transfer beda mesin atm. Mohon gunakan nominal unik contoh: 25.568, 25.246,
                25.347 . </li>
            <li> Harap Perhatikan rekening deposit kami yang aktif sebelum melakukan pengiriman deposit, sehingga
                deposit anda dapat di proses.</li>
        </ol>
    </div>

</div>