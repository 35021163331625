import { Component, OnInit } from '@angular/core';
import { WapUserDetailComponent } from "../../shared/components/wap/wap-user-detail/wap-user-detail.component";

@Component({
  standalone: true,
  selector: 'app-wap-list-game',
  templateUrl: './wap-list-game.component.html',
  styleUrl: './wap-list-game.component.scss',
  imports: [WapUserDetailComponent]
})
export class WapListGameComponent implements OnInit {
  ngOnInit(): void {

  }

  onSearching(e: any) {
    const target = e.target as HTMLInputElement

  }
}
