<div *ngIf="alerts.length > 0" class="fixed top-3 right-3 transition-all duration-200 !z-999">
  @for (alert of alerts; track $index) {
  <div [ngClass]="{
            'bg-green-100 text-green-800 border-green-500': alert.variant === 'success',
            'bg-red-100 text-red-800 border-red-500': alert.variant === 'danger',
            'bg-yellow-100 text-yellow-800 border-yellow-500': alert.variant === 'warning',
            'bg-blue-100 text-blue-800 border-blue-500': alert.variant === 'info',
            'alert-slide-up': alert.isClosing === true,
          }" class="border-l-4 p-4 rounded shadow-lg my-2 alert-slide-down">
    <p>{{ alert.message }}</p>
  </div>
  }
</div>